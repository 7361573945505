import request from '@/utils/request'

// 更新卡信息
export function updateSimCardsInfo(data) {
  return request({
    url: `/batch_operations/sim_cards/update_info`,
    method: 'post',
    data
  })
}

// 更新卡信息
export function updateCarrierSimCardsInfo(data) {
  return request({
    url: `/batch_operations/carrier_sim_cards/update_info`,
    method: 'post',
    data
  })
}

// 运营商卡号列表 强制断网
export function forceNetDownCarrierSimCards(data) {
  return request({
    url: `/batch_operations/carrier_sim_cards/force_net_down`,
    method: 'post',
    data
  })
}

// 运营商卡号列表 取消强制断网
export function cancelForceNetDownCarrierSimCards(data) {
  return request({
    url: `/batch_operations/carrier_sim_cards/cancel_force_net_down`,
    method: 'post',
    data
  })
}

// 客户卡号列表 强制断网
export function forceNetDownAgentSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/force_net_down`,
    method: 'post',
    data
  })
}

// 客户卡号列表 取消强制断网
export function cancelForceNetDownAgentSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/cancel_force_net_down`,
    method: 'post',
    data
  })
}

// 更新库存卡信息
export function updateSimCardInventoriesInfo(data) {
  return request({
    url: `/batch_operations/sim_card_inventories/update_info`,
    method: 'post',
    data
  })
}

// 库存卡批量操作选项数据
export function findInventoryBatchOperationOptions() {
  return [
    { label: '数据更新', value: 'update_info' },
    { label: '变更运营商套餐', value: 'change_carrier_product' },
    { label: '删除SIM卡', value: 'delete' }
  ]
}

// 客户卡号列表 上报语音白名单
export function uploadWhiteListAgentSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/upload_white_list`,
    method: 'post',
    data
  })
}

// 客户卡号列表 验证变更运营商套餐卡号
export function validateChangeSimCardsCarrierProduct(data) {
  return request({
    url: `/batch_operations/sim_cards/change_carrier_product/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 客户卡号列表 变更运营商套餐
export function changeSimCardsCarrierProduct(data) {
  return request({
    url: `/batch_operations/sim_cards/change_carrier_product`,
    method: 'post',
    data
  })
}

// 运营商批量操作选项数据
export function findCarrierBatchOperationOptions() {
  return [
    { label: '数据更新', value: 'update_info' },
    { label: '强制断网', value: 'force_net_down' },
    { label: '取消强制断网', value: 'cancel_force_net_down' },
    { label: '变更运营商套餐', value: 'change_carrier_product' }
  ]
}

// 客户卡批量操作选项数据
export function findSimCardBatchOperationOptions() {
  return request({
    url: `/batch_operations/sim_cards/options`,
    method: 'get'
  })
}

// 提请销卡
export function requestCancelCard(data) {
  return request({
    url: `/batch_operations/sim_cards/request_cancel_card`,
    method: 'post',
    data
  })
}

// 验证VPDN分组配置卡号
export function validateAllSimCardsUpdateVpdn(data) {
  return request({
    url: `/batch_operations/sim_cards/update_vpdn/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// VPDN分组配置卡号
export function allSimCardsUpdateVpdn(data) {
  return request({
    url: `/batch_operations/sim_cards/update_vpdn`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}
// 销卡
export function cancelSimCard(data) {
  return request({
    url: `/batch_operations/sim_cards/cancel_card`,
    method: 'post',
    data
  })
}
